import * as React from "react";
import { IAnsweredQuestion } from "@app/api/modeling/validators";
import { getQuestionsCustomizedProps } from "@app/components/admin/questions/custom-props";
import { IRootState } from "@app/redux";
import { NotUndefined, ObjectId } from "@app/utils/generics";
import QuestionContentTestMode from "@tests-core/components/questions/contents";
import { newContent } from "@tests-core/components/questions/contents/new-content";
import { IUserAnswer } from "@tests-core/schemas/questions/contnets/ans-schema";
import {
	IFullQuestion,
	IShortQuestion,
} from "@tests-core/schemas/questions/helper-schemas";
import { IText } from "@tests-core/schemas/texts/helper-schemas";
import { AnyComponent } from "@tests-core/utils/generics";
import memoizeOne from "memoize-one";
import styled from "react-emotion";
import { connect } from "react-redux";
import { MainButton } from "../users/styles/styledComponents";
import { TestQuestionsNavigation } from "../users/tests/old-test-navigation";
import { getLocale } from "@app/hooks/intl";

export interface IFinishComponentProps {
	questionsLength: number;
	answeredQuestions: ObjectId[];
	answersCorrectedness: (number | null)[];
	onFinish: () => void;
	userAnswers: IState["userAnswers"];
	onQuestionSelect: (qIndex: number) => void;
	totalScore?: number;
}

interface IOwnProps {
	allowSwitchingBetweenQuestions: boolean;
	onFinish: (userAnswers: IAnsweredQuestion[], totalScore?: number) => void;
	showAnswersAfterAnswering: boolean;
	questions: IShortQuestion[] | IFullQuestion[];
	finishComponent: AnyComponent<IFinishComponentProps>;
	texts: IText[];
	defaultCheckedAnswers?: { id: ObjectId; userAnswer: IUserAnswer }[];
	isFinished: boolean;
	disableEditingAnswer?: true;
	generalShuffleKey?: number;
	hideAnswers?: boolean;
}
type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IStateProps;
interface IState {
	questionIds: ObjectId[];
	answersCorrectedness: (number | null)[];
	isFinished: boolean;
	indexOfLastQuestion: number;
	indexOfDisplayedQuestion: number;
	userAnswers: (IUserAnswer | undefined)[];
}

class TestPage extends React.PureComponent<IProps, IState> {
	state = {
		questionIds: [],
		userAnswers: [],
		answersCorrectedness: [],
		isFinished: !!this.props.isFinished,
		indexOfLastQuestion: 0,
		indexOfDisplayedQuestion: 0,
	} as IState;

	questions?: (IShortQuestion | IFullQuestion)[];

	hasCalledOnFinish = false;

	getQuestionContents = memoizeOne((questions?: IProps["questions"]) => {
		if (!questions || questions.length === 0) return [];
		if ((questions as IFullQuestion[])[0].content) {
			return (questions as IFullQuestion[]).map(question =>
				newContent(question.content)
			);
		}
		return (questions as IShortQuestion[]).map(question =>
			newContent(question.shortContent as any)
		);
	});

	componentDidMount() {
		this.questions = this.props.questions;
		if (!this.props.defaultCheckedAnswers) {
			this.setState({
				questionIds: (this.props.questions as IFullQuestion[]).map(
					e => e._id
				),
				indexOfLastQuestion: this.props.questions.length,
			});
		} else {
			const contents =
				this.props.questions.length > 0 &&
				(this.props.questions as IFullQuestion[])[0].content
					? this.getQuestionContents(
							this.props.questions as IFullQuestion[]
					  )
					: [];
			const answeredQuestions: NotUndefined<IProps["defaultCheckedAnswers"]> = [];
			const correctedness: IState["answersCorrectedness"] = [];
			for (let i = 0; i < this.props.questions.length; ++i) {
				const qId = this.props.questions[i]._id;
				const qAnsInfo = this.props.defaultCheckedAnswers.find(
					e => e.id === qId
				);
				if (!qAnsInfo) {
					answeredQuestions[i] = { id: qId, userAnswer: null };
					correctedness[i] = null;
					continue;
				}
				answeredQuestions[i] = qAnsInfo;
				correctedness[i] = contents[i]
					? contents[i].getCreditShare(qAnsInfo.userAnswer) *
					  contents[i].getMaxCredit()
					: null;
			}
			this.setState({
				isFinished:
					answeredQuestions.length >= this.props.questions.length
						? true
						: this.state.isFinished,
				answersCorrectedness: correctedness,
				indexOfLastQuestion: this.props.questions.length,
				indexOfDisplayedQuestion: 0,
				userAnswers: answeredQuestions.map(q => q.userAnswer),
				questionIds: (this.props.questions as IFullQuestion[]).map(
					e => e._id
				),
			});
		}
	}

	changeAnswer = (userAnswer: any) => {
		const newAnswers = [...this.state.userAnswers];
		newAnswers[this.state.indexOfDisplayedQuestion] = userAnswer;
		this.setState({
			userAnswers: newAnswers,
		});
	};

	onQuestionSelect = (qIndex: number) => {
		if (qIndex === -1) {
			this.setState({
				indexOfDisplayedQuestion: this.state.questionIds.length,
			});
			return;
		}
		if (!this.props.allowSwitchingBetweenQuestions) return;
		if (qIndex > this.state.indexOfLastQuestion) return;
		this.setState({
			indexOfDisplayedQuestion: qIndex,
		});
	};

	getAnsweredQuestionIds = () => {
		return this.state.userAnswers.map((e, i) => this.state.questionIds[i]);
	};

	getQuestionByIndex = (index: number) => {
		if (!this.questions) return undefined;
		return this.questions.find(
			q => q._id === this.state.questionIds[index]
		);
	};

	onSubmitAnswer = () => {
		if (!this.questions) return;
		if (this.state.questionIds.length === 0) return;
		if (!this.state.userAnswers[this.state.indexOfDisplayedQuestion]) {
			alert("შემოხაზეთ პასუხი");
			return;
		}
		const currQuestion = this.getQuestionByIndex(
			this.state.indexOfDisplayedQuestion
		);
		if (!currQuestion) return;
		const obj = newContent(
			(currQuestion as IFullQuestion).content ||
				(currQuestion as IShortQuestion).shortContent
		);
		const answerCorrectedness = (currQuestion as IFullQuestion).content
			? obj.getCreditShare(
					this.state.userAnswers[
						this.state.indexOfDisplayedQuestion
					] as any
			  )
			: null;
		const answerCorrectednessUpdated = [
			...this.state.answersCorrectedness,
			answerCorrectedness,
		];
		const nextQuestionInfo =
			this.state.indexOfDisplayedQuestion === this.questions.length - 1
				? { isFinished: true as true }
				: {
						isFinished: false as false,
						id: this.questions[
							this.state.indexOfDisplayedQuestion + 1
						]._id!,
				  };
		if (!nextQuestionInfo.isFinished) {
			this.setState({
				answersCorrectedness: answerCorrectednessUpdated,
				questionIds: [...this.state.questionIds, nextQuestionInfo.id],
				indexOfDisplayedQuestion: this.props.showAnswersAfterAnswering
					? this.state.indexOfDisplayedQuestion
					: this.state.indexOfDisplayedQuestion + 1,
			});
		} else {
			console.log(
				"here",
				this.props.showAnswersAfterAnswering
					? this.state.indexOfDisplayedQuestion
					: this.state.questionIds.length
			);
			this.setState({
				answersCorrectedness: answerCorrectednessUpdated,
				isFinished: true,
				indexOfLastQuestion: this.state.questionIds.length,
				indexOfDisplayedQuestion: this.props.showAnswersAfterAnswering
					? this.state.indexOfDisplayedQuestion
					: this.state.questionIds.length,
			});
		}
	};

	calculateTotalScore = () => {
		let totalScore = 0;
		const contents =
			this.questions &&
			this.questions.length > 0 &&
			(this.questions as IFullQuestion[])[0].content
				? this.getQuestionContents(this.questions as IFullQuestion[])
				: [];
		for (let i = 0; i < this.state.userAnswers.length; i++) {
			const userAnswer = this.state.userAnswers[i];
			if (!contents[i]) continue;
			if (userAnswer !== undefined && userAnswer !== null) {
				const score =
					contents[i].getCreditShare(userAnswer) *
					contents[i].getMaxCredit();
				totalScore += score;
			}
		}
		return totalScore;
	};

	onFinish = () => {
		const userAnswers: IAnsweredQuestion[] = [];
		for (let i = 0; i < this.state.userAnswers.length; i++) {
			const userAnswer = this.state.userAnswers[i];
			if (userAnswer !== undefined && userAnswer !== null) {
				userAnswers.push({
					id: this.state.questionIds[i],
					userAnswer,
					answeredAt: new Date(), // FIXME: get correct date
				});
			}
		}
		this.props.onFinish(userAnswers, this.calculateTotalScore());
	};

	onNextQuestion = () => {
		this.setState({
			indexOfDisplayedQuestion: Math.min(
				this.state.indexOfLastQuestion,
				this.state.indexOfDisplayedQuestion + 1
			),
		});
	};

	getQuestionsCustomizedProps = memoizeOne((locale: string) => {
		return getQuestionsCustomizedProps(locale, true);
	});

	render() {
		if (!this.questions) return null;
		const navigation = (
			<TestQuestionsNavigation
				onQuestionSelect={this.onQuestionSelect}
				indexOfLastQuestion={this.state.indexOfLastQuestion}
				indexOfSelectedQuestion={this.state.indexOfDisplayedQuestion}
				questionsLength={this.questions.length}
			/>
		);
		const showFinishPage =
			this.state.indexOfDisplayedQuestion ===
			this.state.questionIds.length;
		if (this.state.questionIds.length === 0) return null;
		const currentQuestion = this.getQuestionByIndex(
			this.state.indexOfDisplayedQuestion
		);
		const FinalComponent = this.props.finishComponent;
		if (!currentQuestion && !showFinishPage) return null;
		const isLast =
			this.state.indexOfDisplayedQuestion ===
			this.state.indexOfLastQuestion;
		const text =
			!currentQuestion || !currentQuestion.textId
				? undefined
				: this.props.texts.find(t => t._id === currentQuestion.textId);
		return (
			<React.Fragment>
				<div style={{ display: showFinishPage ? "block" : "none" }}>
					<FinalComponent
						questionsLength={this.questions.length}
						answeredQuestions={this.getAnsweredQuestionIds()}
						answersCorrectedness={this.state.answersCorrectedness}
						onFinish={this.onFinish}
						userAnswers={this.state.userAnswers}
						onQuestionSelect={this.onQuestionSelect}
						totalScore={this.calculateTotalScore()}
					/>
				</div>
				{!showFinishPage && !!currentQuestion && (
					<QuestionContentContainer>
						<QuestionContentTestMode
							content={
								(currentQuestion as IFullQuestion).content ||
								(currentQuestion as IShortQuestion).shortContent
							}
							onUserAnswerChange={this.changeAnswer}
							displayAnswer={
								!!(currentQuestion as IFullQuestion).content &&
								!this.props.hideAnswers
							}
							userAnswer={
								this.state.userAnswers[
									this.state.indexOfDisplayedQuestion
								]
							}
							customized={this.getQuestionsCustomizedProps(
								getLocale()
							)}
							disableEditingAnswer={
								this.props.disableEditingAnswer
							}
							shuffleKey={this.props.generalShuffleKey}
						/>
						{/* TODO: display text */}
						{isLast && (
							<MainButton onClick={this.onSubmitAnswer}>
								ტესტის დასრულება
							</MainButton>
						)}
						{!isLast && (
							<MainButton onClick={this.onNextQuestion}>
								შემდეგი
							</MainButton>
						)}
					</QuestionContentContainer>
				)}
				{navigation}
			</React.Fragment>
		);
	}
}

const QuestionContentContainer = styled("div")({
	margin: "0 auto",
	width: "80%",
});

const mapStateToProps = (state: IRootState) => ({
	testTypes: state.testTypes,
	folders: state.folders,
});

export default connect<IStateProps, null, IOwnProps>(mapStateToProps)(TestPage);
