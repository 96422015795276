import * as React from "react";
import lowerLeft from "./styles/img/newimgs/lower-left.png";
import LowerRight from "./styles/img/newimgs/lower-right.png";
import point from "./styles/img/newimgs/4x4.png";
import styled from "react-emotion";
import upperLeft from "./styles/img/newimgs/upper-left.png";
import upperRight from "./styles/img/newimgs/upper-right.png";
import zazusSecond from "./styles/img/newimgs/zazus2.png";
import { Helmet } from "react-helmet";
import { History } from "history";
import { htmlSubstring } from "@app/utils/html-string";
import { match } from "react-router-dom";
import { ObjectId } from "@app/utils/generics";
const content1 =
	"კურსი შექმნილია აბიტურიენტებისთვის, რომელთაც სურთ ისწავლონ ან გაიღრმავონ ცოდნა ისტორიაში, რათა წარმატებით გაართვან თავი ისტორიის ერთიან ეროვნულ გამოცდას.";
const content2 =
	"ამ მიზნის მისაღწევად მურწყუ გთავაზობთ სასწავლო კურსს, რომელიც შედგენილია ერთიანი ეროვნული გამოცდების სასწავლო პროგრამის მიხედვით და მთლიანად მოიცავს ყველა იმ ტიპის დავალებას, რომელიც აბიტურიენტს დახვდება ისტორიის ერთიან ეროვნულ გამოცდაზე.";
const content3 =
	"კურსის გავლის შემდეგ თქვენ გექნებათ ინფორმაცია ყველა მნიშვნელოვან ისტორიულ მოვლენასა თუ თარიღზე. შეგეძლებათ წარმატებით გაართვათ თავი როგორც ტესტური, ისე ქრონოლოგიური ტიპის დავალებებს, უპასუხოთ რუკის, ილუსტრაციისა თუ არგუმენტაციის სავარჯიშოს და წარმატებით დაკითხოთ დოკუმენტი.";
const content4 =
	"კურსის სწავლების ტიპი ასეთია – თქვენ გაეცნობით თეორიულ მასალებს და პასუხს გასცემთ მის მიხედვით დასმულ ტესტურ კითხვებს. თეორიული მასალის სწავლისთანავე თქვენ შეგხვდებათ რუკის, ილუსტრაციისა ან არგუმენტაციის სავარჯიშო. თითოეულ სავარჯიშოს თან ახლდება ახსნა-განმარტება, რომლითაც პირდაპირ კომუნიკაციას დავამყარებთ თქვენთან. ჩვენ დავაკვირდებით სავარჯიშოებზე თქვენ მიერ გაცემული პასუხების სისწორესაც. თუ რომელიმე მათგანის შესრულება რთული აღმოჩნდება, დამატებითი მეცადინეობის მიზნით, შემოგთავაზებთ თქვენთვის პრობლემური სავარჯიშოს ანალოგიურ დავალებებს.";

const content = `<p>${content1}</p><p>${content2}</p><p>${content3}</p><p>${content4}</p>`;
interface IProps {
	history: History;
	match: match<{ courseId: ObjectId }>;
}

interface IState {
	windowWidth: number;
}

class CourseDescriptionClass extends React.Component<IProps, IState> {
	state: IState = {
		windowWidth: document.body.clientWidth,
	};

	courseId = this.props.match.params.courseId;

	constructor(props) {
		super(props);
		window.addEventListener("resize", this.onResize);
	}

	onResize = () => {
		this.setState({
			windowWidth: document.body.clientWidth,
		});
	};

	startCourse = () => {
		this.props.history.push(`/courses/${this.courseId}/guide`);
	};

	componentWillUnmount() {
		window.removeEventListener("resize", this.onResize);
	}

	render() {
		return (
			<div>
				<Helmet
					bodyAttributes={{
						style: "background-color: #152958" as any,
					}}
				/>
				<Container style={{ fontFamily: "FiraGO" }}>
					<FancyGragniliContainer
						isMobile={this.state.windowWidth < 600}
					/>
					<StartButton
						style={{ fontFamily: "FiraGO" }}
						onClick={this.startCourse}
					>
						ისტორიის კურსის დაწყება
					</StartButton>
				</Container>
			</div>
		);
	}
}

interface IFancyGragniliContainerProps {
	isMobile: boolean;
}

class FancyGragniliContainer extends React.Component<
	IFancyGragniliContainerProps
> {
	state = {
		isOpen: false,
	};

	onClick = () => {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	};

	render() {
		return this.state.isOpen ? (
			<Gragnili isOpen={this.state.isOpen} onClick={this.onClick} />
		) : this.props.isMobile ? (
			<Gragnili isOpen={this.state.isOpen} onClick={this.onClick} />
		) : (
			<FancyGragnili isOpen={this.state.isOpen} onClick={this.onClick} />
		);
	}
}

interface IFancyGragniliProps {
	onClick: () => void;
	isOpen: boolean;
}

const FancyGragnili: React.FC<IFancyGragniliProps> = props => {
	return (
		<FancyZazus>
			<TextForFancy>
				{content && (
					<>
						<div
							className="descText"
							dangerouslySetInnerHTML={{
								__html: htmlSubstring(content, 225),
							}}
						/>
						<div
							style={{
								fontSize: "16px",
								cursor: "pointer",
								fontWeight: "bold",
							}}
							onClick={props.onClick}
						>
							... ვრცლად ნახვა
						</div>
					</>
				)}
			</TextForFancy>
		</FancyZazus>
	);
};

interface IGragniliProps {
	onClick: () => void;
	isOpen: boolean;
}

const Gragnili: React.FC<IGragniliProps> = props => {
	return (
		<GragniliBase>
			<Gragnili3 />
			<Gragnili1>
				<Gragnili1child1 />
				<Gragnili1child2 />
				<Gragnili1child3 />
				<Gragnili1child4 />
				<Gragnili1child5 />
				<Gragnili1child6 />
			</Gragnili1>
			<Gragnili2>
				<Gragnili2child1 />
				<Gragnili2child2 />
				<Gragnili2child3 />
				<Gragnili2child4 />
			</Gragnili2>
			<Gragnili4>
				<TextForGragnili>
					{content && (
						<React.Fragment>
							<div
								className="descText"
								dangerouslySetInnerHTML={{
									__html: props.isOpen
										? content
										: htmlSubstring(content, 250),
								}}
							/>
							{props.isOpen ? (
								<span
									style={{
										fontSize: "16px",
										cursor: "pointer",
										fontWeight: "bold",
									}}
									onClick={props.onClick}
								>
									აკეცვა
								</span>
							) : (
								<span
									style={{
										fontSize: "16px",
										cursor: "pointer",
										fontWeight: "bold",
									}}
									onClick={props.onClick}
								>
									... ვრცლად ნახვა
								</span>
							)}
						</React.Fragment>
					)}
				</TextForGragnili>
			</Gragnili4>
		</GragniliBase>
	);
};

const TextForFancy = styled("div")({
	height: 308,
	paddingLeft: 235,
	paddingTop: 107,
	paddingRight: 115,
	fontSize: 14,
	color: "white",
	overflow: "hidden",
});

const TextForGragnili = styled("div")({
	paddingLeft: 110,
	paddingTop: 107,
	paddingRight: 110,
	fontSize: 14,
	color: "white",
	paddingBottom: 20,
});

const Container = styled("div")({
	marginTop: 100,
	width: 800,
	maxWidth: "100%",
	marginLeft: "auto",
	marginRight: "auto",
});

const FancyZazus = styled("div")({
	background: `url(${zazusSecond})`,
	width: 600,
	height: 350,
	backgroundRepeat: "no-repeat",
	marginLeft: "auto",
	marginRight: "auto",
});

const StartButton = styled("button")({
	background: "#152958",
	border: 2,
	borderStyle: "solid",
	borderColor: "white",
	color: "white",
	borderRadius: 30,
	display: "block",
	margin: "50px auto 20px auto",
	padding: "13px 50px",
	outline: "none",
	cursor: "pointer",
	[`:hover`]: {
		background: "white",
		color: "#152958",
		fontWeight: "bold",
		boxShadow: "#3887ad 0 0 12px",
	},
	transition: "0.3s",
	minWidth: 280,
	boxShadow: "#3887ad 0 0 12px, #3887ad 0 0 12px inset",
});

const absoluteStyles = {
	position: "absolute" as "absolute",
	width: "100%",
	height: "100%",
	left: 0,
	top: 0,
	right: 0,
	bottom: 0,
};

const GragniliBase = styled("div")({
	display: "block",
	position: "relative",
	width: 600,
	marginLeft: "auto",
	marginRight: "auto",
	maxWidth: "100%",
});

const Gragnili1 = styled("div")({
	...absoluteStyles,
	zIndex: 2,
});

const Gragnili1child1 = styled("div")({
	position: "absolute",
	width: 96,
	background: "#152958",
	height: 500,
});

const Gragnili1child2 = styled("div")({
	position: "absolute",
	width: 89,
	background: "#152958",
	height: 100,
	left: 100,
	top: 0,
});

const Gragnili1child3 = styled("div")({
	position: "absolute",
	width: 100,
	background: "#152958",
	height: 98,
	right: 0,
});

const Gragnili1child4 = styled("div")({
	position: "absolute",
	width: 100,
	background: "#152958",
	height: 90,
	left: 0,
	bottom: 0,
});

const Gragnili1child5 = styled("div")({
	position: "absolute",
	width: 141,
	background: "#152958",
	height: 96,
	right: 0,
	bottom: 0,
});

const Gragnili1child6 = styled("div")({
	position: "absolute",
	width: 100,
	background: "#152958",
	height: 96,
	left: 0,
	top: 0,
});

const Gragnili2 = styled("div")({
	...absoluteStyles,
	zIndex: 3,
});

const Gragnili2child1 = styled("div")({
	position: "absolute",
	width: 97,
	height: 97,
	background: `url(${lowerLeft})`,
	left: 3,
	bottom: 0,
});

const Gragnili2child2 = styled("div")({
	position: "absolute",
	width: 101,
	height: 97,
	background: `url(${LowerRight})`,
	right: 96,
	bottom: 0,
});

const Gragnili2child3 = styled("div")({
	position: "absolute",
	width: 101,
	height: 97,
	background: `url(${upperLeft})`,
	left: 96,
	top: 0,
});

const Gragnili2child4 = styled("div")({
	position: "absolute",
	width: 72,
	height: 97,
	background: `url(${upperRight})`,
	top: 0,
	right: 28,
});

const Gragnili3 = styled("div")({
	...absoluteStyles,
	background: `url(${point}) left 96px top repeat-y,
				 url(${point}) right 96px top repeat-y,
				 url(${point}) top left 187px repeat-x,
				 url(${point}) top 93px left 187px repeat-x,
				 url(${point}) bottom left 187px repeat-x
				 `,
	zIndex: 1,
});

const Gragnili4 = styled("div")({
	...absoluteStyles,
	zIndex: 5,
	position: "relative",
});

export default CourseDescriptionClass;
