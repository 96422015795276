import {
	IAPUTSave,
	IRGETBegin,
	IRGETBeginOffline,
	IRGETGetOne,
	IRGETReview,
	IRPUTSave,
	RGETBeginOfflineSchema,
	RGETBeginSchema,
	RGETGetOneSchema,
	RGETReviewSchema,
	IRGETGetPublicModelling,
	RGETGetPublicModellingSchema,
} from "./validators";
import { IRequest } from "../requests";
import Joi from "@tests-core/utils/joi";

export class ModelingController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	get = (args: { testId: number }): Promise<IRGETGetOne> =>
		this.Request.send("GET", "/api/modeling/:testId", args, null, {
			responseSchema: RGETGetOneSchema,
		}).then((data: IRGETGetOne) => {
			return data;
		});

	getOffline = (args: { testId: number }): Promise<IRGETGetOne> =>
		this.Request.send("GET", "/api/modeling/:testId/offline", args, null, {
			responseSchema: RGETGetOneSchema,
		});

	getPublic = (args: { testId: number }): Promise<IRGETGetPublicModelling> =>
		this.Request.send("GET", "/api/modeling/:testId/public", args, null, {
			responseSchema: RGETGetPublicModellingSchema,
		});

	begin = (args: {
		testId: number;
	}): Promise<IRGETBegin & { startedAt: Date }> =>
		this.Request.send("GET", "/api/modeling/:testId/begin", args, null, {
			responseSchema: RGETBeginSchema.keys({
				startedAt: Joi.date().required(),
			}),
		}).then((data: IRGETBegin & { startedAt: Date }) => {
			return data;
		});

	beginOffline = (args: { testId: number }): Promise<IRGETBeginOffline> =>
		this.Request.send(
			"GET",
			"/api/modeling/:testId/begin-offline",
			args,
			null,
			{
				responseSchema: RGETBeginOfflineSchema,
			}
		).then((data: IRGETBeginOffline) => {
			return data;
		});

	review = (args: { testId: number }): Promise<IRGETReview> =>
		this.Request.send("GET", "/api/modeling/:testId/review", args, null, {
			responseSchema: RGETReviewSchema,
		}).then((data: IRGETReview) => {
			return data;
		});

	save = (data: IAPUTSave): Promise<IRPUTSave> =>
		this.Request.send("PUT", "/api/modeling/:testId/save", data, null);
}
