import * as React from "react";
import CheckIcon from "@material-ui/icons/CheckCircleRounded";
import GlowingBar from "../widgets/glowing-bar";
import FancyLoading from "../widgets/fancy-loading";
import Lock from "@material-ui/icons/Lock";
import styled, { css } from "react-emotion";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { History } from "history";
import { inject } from "@app/modules";
import { IRootState } from "@app/redux";
import { match } from "react-router-dom";
import { round } from "@app/commonJavascript";
import {
	IFolderSingleItem,
	ItemType,
	ITestItem,
} from "@app/api/folders/helper-schemas";

interface IOwnProps {
	match: match<{ courseId: string; folderId: string }>;
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type ISubTopicsProps = IOwnProps & IStateProps;

class SubTopics extends React.Component<ISubTopicsProps> {
	courseId = this.props.match.params.courseId;
	folderId = this.props.match.params.folderId;

	_Folder = inject("FolderModel");
	_UserFolderProgressService = inject("UserFolderProgressService");
	_CourseFetchingController = inject("CourseFetchingController");
	_TestTypesController = inject("TestTypesController");

	componentDidMount() {
		this._CourseFetchingController
			.loadWholeCourseSkeleton({
				courseId: this.courseId,
				folderId: this.folderId,
			})
			.then(data => {
				//
			});
		this._TestTypesController.getAll();
	}

	redirectToTestPage = (testTypeId: string, isLocked: boolean) => {
		if (isLocked) return null;
		return this.props.history.push(
			`/courses/${this.courseId}/folders/${this.folderId}/tests/${testTypeId}`
		);
	};

	redirect = (item: IFolderSingleItem, isLocked: boolean) => {
		if (isLocked) return null;
		if (item.type === ItemType.folder) {
			return this.props.history.push(
				`/courses/${this.courseId}/folders/${item.id}/content`
			);
		}
		this.redirectToTestPage(item.id, isLocked);
	};

	returnToCourse = () => {
		this.props.history.push(`/courses/${this.courseId}/guide`);
	};

	render() {
		const folder = this._Folder.findByIdSync(this.folderId);

		if (!folder) {
			return <FancyLoading />;
		}

		if (!folder.items) {
			return <div>ცარიელია</div>;
		}

		const progressDoc = this._UserFolderProgressService.getProgressDocSync({
			courseId: this.courseId,
			folderId: this.folderId,
			userId: this.props.userData.id,
		});
		const folderProgress = !progressDoc ? 0 : progressDoc.progress;
		return (
			<>
				<Helmet
					bodyAttributes={{
						style: "background-color: #152958" as any,
					}}
				/>
				<div>
					<Name>{folder.name}</Name>
					<InsideContainer>
						<div className={PercentageBarContainerCSS}>
							<BarContainer>
								<GlowingBar
									percentage={round(folderProgress * 100, 2)}
								/>
							</BarContainer>
							<Percentage>
								{round(folderProgress * 100, 2)}%
							</Percentage>
						</div>

						{folder.items.map(item => {
							const itemProgress = this._UserFolderProgressService.getProgressOfItemSync(
								{
									courseId: this.courseId,
									itemId: item.id,
									itemType: item.type,
									folderId: folder._id,
									userId: this.props.userData.id,
								}
							);
							const isLocked = this._UserFolderProgressService.isItemLockedSync(
								{
									courseId: this.courseId,
									itemId: item.id,
									itemType: item.type,
									folderId: folder._id,
									userId: this.props.userData.id,
								}
							);
							if (
								item.type !== ItemType.folder &&
								item.type !== ItemType.test
							)
								return null;
							return (
								<EachSubtopicContainer
									key={item.id}
									onClick={() =>
										this.redirect(item, isLocked)
									}
									isLocked={isLocked}
									item={item}
									progress={itemProgress}
								/>
							);
						})}
						<SubTopicsButton onClick={this.returnToCourse}>
							უკან დაბრუნება
						</SubTopicsButton>
					</InsideContainer>
					;
				</div>
			</>
		);
	}
}

interface IEachSubtopicProps {
	name: string;
	progress: number;
}

const EachSubtopic: React.FC<IEachSubtopicProps> = props => {
	return (
		<div>
			<Text>{props.name}</Text>
		</div>
	);
};

interface IEachSubtopicContainerProps {
	isLocked: boolean;
	item: IFolderSingleItem;
	progress: number;
	onClick: () => void;
}

const EachSubtopicContainer: React.FC<IEachSubtopicContainerProps> = props => (
	<EachSubtopicContainerCSS onClick={props.onClick} isLocked={props.isLocked}>
		{props.item.type === ItemType.folder && (
			<EachSubtopic name={props.item.name} progress={props.progress} />
		)}
		{props.item.type === ItemType.test && (
			<StartTest
				testItem={props.item}
				progress={props.progress}
				redirect={props.onClick}
			/>
		)}
		<GlassEffect />
		{props.isLocked && <Lock className={LockedItemCSS} />}
		{!props.isLocked && <GlossyPercentage progress={props.progress} />}
	</EachSubtopicContainerCSS>
);

interface IStartTestProps {
	testItem: ITestItem;
	progress: number;
	redirect: (testTypeId: string) => void;
}

const StartTest: React.FC<IStartTestProps> = props => {
	const testAlreadyDone = props.progress === 1;
	return (
		<div>
			<Text>{props.testItem.name}</Text>
			{testAlreadyDone && <CheckIcon className={CheckIconCSS} />}
			<SubTopicsButton onClick={() => props.redirect(props.testItem.id)}>
				{testAlreadyDone ? "გადახედვა" : "დაწყება"}
			</SubTopicsButton>
		</div>
	);
};

const GlossyPercentage: React.FC<{ progress: number }> = props => {
	return (
		<div className={PercentageCSS}>
			<span>{round(props.progress * 100, 1)}%</span>
		</div>
	);
};

const GlassEffect = styled("div")({
	position: "absolute",
	width: "60%",
	left: "-10%",
	height: "100%",
	top: 0,
	// tslint:disable-next-line:max-line-length
	background:
		"linear-gradient(to right, #ffffff05 56%, #ffffff33), radial-gradient(ellipse at top right, #ffffff2e 0%, #fff0 50%)",
	transform: "skew(-42deg)",
});

const LockedItemCSS = css`
	position: absolute;
	font-size: 150px !important;
	bottom: -40px;
	left: -60px;
	color: rgba(255, 255, 255, 0.3);
`;

const SubTopicsButton = styled("div")({
	width: 300,
	maxWidth: "100%",
	height: 50,
	position: "relative",
	zIndex: 2,
	border: "2px solid white",
	borderRadius: 30,
	boxShadow: "0 0 5px #6ac5d4ab",
	color: "white",
	margin: "10px auto",
	paddingTop: 10,
	fontSize: 20,
	textAlign: "center",
	cursor: "pointer",
	transition: "0.3s",
	fontfamily: "FiraGo",
	"&:hover": {
		backgroundColor: "white",
		color: "#152958",
	},
});

const InsideContainer = styled("div")({
	width: "80%",
	margin: "0 auto",
});

const BarContainer = styled("div")({
	padding: "40px 0px",
	width: "90%",
	flex: 1,
});

const Percentage = styled("div")({
	fontSize: 30,
	color: "white",
	paddingLeft: 10,
});

const EachSubtopicContainerCSS = styled("div")(
	{
		width: "100%",
		height: 150,
		border: "2px solid white",
		borderRadius: 10,
		boxShadow: "0 0 5px #6ac5d4ab",
		margin: "20px auto",
		position: "relative",
		overflow: "hidden",
		cursor: "pointer",
		transition: "0.3s",
	},
	(props: { isLocked: boolean }) => {
		if (!props.isLocked) {
			return {
				"&:hover": {
					boxShadow: "0 0 10px #a9d3daab, 0 0 10px #a9d3daab inset",
				},
			};
		}
		return null;
	}
);

const PercentageCSS = css`
	color: white;
	opacity: 0.1;
	font-size: 60px;
	position: absolute;
	top: -16px;
	right: -10px;
`;

const Text = styled("div")({
	width: "80%",
	height: "100%",
	color: "white",
	fontFamily: "FiraGO",
	fontWeight: "bold",
	padding: 15,
	fontSize: 20,
	display: "inline-block",
});

const CheckIconCSS = css`
	float: right;
	margin: 7px;
	color: white;
`;

const PercentageBarContainerCSS = css`
	display: flex;
	width: 100%;
	align-items: center;
`;

const Name = styled("div")({
	padding: 20,
	paddingBottom: 0,
	textAlign: "center",
	fontSize: 26,
	color: "white",
	fontFamily: "FiraGO",
});

const mapStateToProps = (state: IRootState) => ({
	courses: state.courses,
	folders: state.folders,
	folderProgresses: state.userFolderProgresses,
	testTypes: state.testTypes,
	userData: state.user.userData!,
});

export default connect<IStateProps, null, IOwnProps>(mapStateToProps)(
	SubTopics
);
