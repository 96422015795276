import * as React from "react";
import CheckMark from "@material-ui/icons/Check";
import LoadingSVG from "@app/components/styles/img/loading";
import Popup, { PopupContent } from "@app/components/widgets/Popup";
import Lock from "@material-ui/icons/Lock";
import {
	CheckContainerCSS,
	Container,
	EachPillar,
	LockContainerCSS,
	NameWithoutPhoto,
	PathDownward,
	PathForLeft,
	PathForRight,
	PillarImage,
	PillarImg,
	RedBorder,
	WhiteSpace,
	CanGoToFolder,
} from "./styles";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { History } from "history";
import {
	IRFolder,
	IFolderItem,
	ITestItem,
	ItemType,
	IFolderSingleItem,
} from "@app/api/folders/helper-schemas";
import { inject } from "@app/modules";
import { IRootState } from "@app/redux";
import { match } from "react-router-dom";
import { ObjectId } from "@tests-core/utils/joi";

interface IOwnProps {
	match: match<{ courseId: string }>;
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IGuideProps = IOwnProps & IStateProps;

interface IGuideState {
	isMobile: boolean;
	popupOpen: boolean;
	openFolder: ObjectId | null;
	undoneFolders: IFolderSingleItem[] | null;
}

class Guide extends React.Component<IGuideProps, IGuideState> {
	state: IGuideState = {
		isMobile: window.innerWidth < 880,
		popupOpen: false,
		openFolder: null,
		undoneFolders: null,
	};

	courseId = this.props.match.params.courseId;

	loadArray = new Array(3).fill(0);

	_Folder = inject("FolderModel");
	_Course = inject("CourseModel");
	_UserFolderProgressService = inject("UserFolderProgressService");
	_CourseFetchingController = inject("CourseFetchingController");

	componentDidMount() {
		window.addEventListener("resize", this.onResize);
		this._CourseFetchingController
			.loadWholeCourseSkeleton({
				courseId: this.courseId,
			})
			.then(data => {
				//
			});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.onResize);
	}

	onResize = () => {
		const isMobile = window.innerWidth < 880;
		if (this.state.isMobile !== isMobile) {
			this.setState({
				isMobile,
			});
		}
	};

	onPopupClose = () => {
		this.setState({
			popupOpen: false,
			openFolder: null,
			undoneFolders: null,
		});
	};

	onPillarChoose = (folderId: ObjectId, isLocked: boolean) => {
		if (isLocked) {
			this.setState({
				popupOpen: true,
				openFolder: folderId,
			});
			this.undoneFoldersList(folderId);
			return;
		}
		return this.props.history.push(
			`/courses/${this.courseId}/folders/${folderId}`
		);
	};

	undoneFoldersList = (folderId: ObjectId) => {
		const course = this._Course.findByIdSync(this.courseId);

		if (!course) return;
		const rootFolder = this._Folder.findByIdSync(course.rootFolder);
		if (!rootFolder) return;
		const folderItems = (rootFolder.items || []).filter(
			f => f.type === ItemType.folder
		) as IFolderItem[];
		const undoneFolders: IFolderSingleItem[] = [];
		for (let i = 0; i < folderItems.length; i++) {
			if (folderItems[i].id === folderId) {
				break;
			}
			const itemProgress = this._UserFolderProgressService.getProgressOfItemSync(
				{
					itemId: folderItems[i].id,
					itemType: folderItems[i].type,
					folderId: rootFolder._id,
					courseId: this.courseId,
					userId: this.props.userData.id,
				}
			);

			console.log(itemProgress);
			if (itemProgress < 1) {
				undoneFolders.push(folderItems[i]);
			}
		}
		this.setState({
			undoneFolders,
		});
	};

	onUndoneFolderClick = folderId => {
		return this.props.history.push(
			`/courses/${this.courseId}/folders/${folderId}`
		);
	};

	gotoTestPage = (testItem: ITestItem) => {
		const course = this._Course.findByIdSync(this.courseId);
		if (!course) return;
		return this.props.history.push(
			`/courses/${this.courseId}/folders/${course.rootFolder}/tests/${testItem.id}`
		);
	};

	render() {
		const course = this._Course.findByIdSync(this.courseId);
		const rootFolder = !course
			? undefined
			: this._Folder.findByIdSync(course.rootFolder);
		if (!rootFolder) {
			return (
				<>
					<Helmet
						bodyAttributes={{
							style: "background-color: #152958" as any,
						}}
					/>
					<Container>
						<RedBorder>
							{this.loadArray.map((e, i) => {
								const dir = this.state.isMobile
									? "Down"
									: i % 2 === 0
									? "Left"
									: "Right";
								return (
									<EachPillarContainer
										key={i}
										isLast={i === this.loadArray.length - 1}
										dir={dir}
										isLoaded={false}
									/>
								);
							})}
						</RedBorder>
					</Container>
				</>
			);
		}
		const folderItems = (rootFolder.items || []).filter(
			f => f.type === ItemType.folder && !f.isHidden
		) as IFolderItem[];
		return (
			<>
				<Helmet
					bodyAttributes={{
						style: "background-color: #152958" as any,
					}}
				/>
				{this.state.popupOpen && (
					<Popup onClose={() => this.onPopupClose()}>
						<PopupContent
							style={{
								background: "transparent",
								padding: 0,
								width: 556,
								maxWidth: "90%",
							}}
						>
							<div
								style={{
									color: "white",
									fontSize: 20,
									background:
										"linear-gradient(to top right, #08257d, #1f7cf5)",
									padding: "50px 18px",
									borderRadius: 7,
								}}
							>
								{this.state.undoneFolders &&
								this.state.undoneFolders.length > 1 ? (
									<div
										style={{
											fontSize: 24,
											marginBottom: 20,
										}}
									>
										ამ თემის გასახსნელად თქვენ უნდა გაიაროთ
										შემდეგი ქვეთემები:
									</div>
								) : (
									<div
										style={{
											fontSize: 24,
											marginBottom: 20,
										}}
									>
										ამ თემის გასახსნელად თქვენ უნდა გაიაროთ
										შემდეგი ქვეთემა:
									</div>
								)}

								<div>
									{this.state.undoneFolders &&
										this.state.undoneFolders.map(
											(folder, index) =>
												index === 0 ||
												!this._UserFolderProgressService.isItemLockedSync(
													{
														courseId: this.courseId,
														itemId: folder.id,
														itemType: folder.type,
														folderId:
															rootFolder._id,
														userId: this.props
															.userData.id,
													}
												) ? (
													<div
														key={folder.id}
														className={
															CanGoToFolder
														}
														onClick={() =>
															this.onUndoneFolderClick(
																folder.id
															)
														}
													>
														{folder.name}
													</div>
												) : (
													<div
														style={{
															marginTop: 10,
														}}
														key={folder.id}
													>
														{folder.name}
													</div>
												)
										)}
								</div>
							</div>
						</PopupContent>
					</Popup>
				)}
				<Container>
					<RedBorder>
						{folderItems.map((item, i) => {
							const dir = this.state.isMobile
								? "Down"
								: i % 2 === 0
								? "Left"
								: "Right";
							const itemProgress = this._UserFolderProgressService.getProgressOfItemSync(
								{
									courseId: this.courseId,
									itemId: item.id,
									itemType: item.type,
									folderId: rootFolder._id,
									userId: this.props.userData.id,
								}
							);
							const isLocked = this._UserFolderProgressService.isItemLockedSync(
								{
									courseId: this.courseId,
									itemId: item.id,
									itemType: item.type,
									folderId: rootFolder._id,
									userId: this.props.userData.id,
								}
							);

							return (
								<EachPillarContainer
									key={item.id}
									name={item.name}
									onClick={this.onPillarChoose}
									id={item.id}
									isLast={i === folderItems.length - 1}
									dir={dir}
									folder={
										this.props.folders[item.id] &&
										this.props.folders[item.id]!.info
									}
									isLoaded={true}
									isLocked={isLocked}
									progress={itemProgress}
								/>
							);
						})}
						<div style={{ height: 30 }} />
					</RedBorder>
				</Container>
			</>
		);
	}
}

type IEachPillarContainer = (
	| {
			isLoaded: false;
	  }
	| {
			isLoaded: true;
			id: ObjectId;
			folder?: IRFolder;
			name: string;
			onClick: (id: ObjectId, isLocked: boolean) => void;
			isLocked: boolean;
			progress: number;
	  }
) & {
	isLast?: boolean;
	dir: "Left" | "Right" | "Down";
};

const EachPillarContainer: React.FC<IEachPillarContainer> = props => {
	const style =
		props.dir === "Down"
			? {
					width: 200,
					height: 500,
					margin: "0 auto",
					marginTop: props.isLast ? 0 : 60,
			  }
			: undefined;
	return (
		<div style={style}>
			<EachPillar
				onClick={
					props.isLoaded
						? () => props.onClick(props.id, props.isLocked)
						: undefined
				}
				isRight={props.dir === "Right"}
				isLocked={props.isLoaded ? props.isLocked : false}
			>
				<WhiteSpace>
					{props.isLoaded ? (
						<>
							{props.folder && props.folder.photo ? (
								<>
									{" "}
									<div style={{ textAlign: "center" }}>
										{props.name}
									</div>
									<PillarImage url={props.folder.photo} />
								</>
							) : (
								<NameWithoutPhoto>
									{props.name}
								</NameWithoutPhoto>
							)}
						</>
					) : (
						<div style={{ marginLeft: 40 }}>
							<LoadingSVG width={90} height={90} />
						</div>
					)}
				</WhiteSpace>
				<PillarImg>
					{props.isLoaded && props.isLocked && (
						<div className={LockContainerCSS}>
							<Lock />
						</div>
					)}
					{props.isLoaded && !props.isLocked && props.progress === 1 && (
						<div className={CheckContainerCSS}>
							<CheckMark />
						</div>
					)}
				</PillarImg>
			</EachPillar>
			{props.dir === "Down" && !props.isLast && <PathDownward />}
			{props.dir === "Left" && !props.isLast && <PathForLeft />}
			{props.dir === "Right" && !props.isLast && <PathForRight />}
		</div>
	);
};

const mapStateToProps = (state: IRootState) => ({
	courses: state.courses,
	folders: state.folders,
	folderProgresses: state.userFolderProgresses,
	userData: state.user.userData!,
});

export default connect<IStateProps, null, IOwnProps>(mapStateToProps)(Guide);
