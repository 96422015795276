import { Course } from "@app/models/course";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { useModelDocById, useModelDocs } from "m-model-react";
import { useCallback, useMemo } from "react";
import { IResourceLoadingInfo, useResourceInfoWithLoading } from "./fetch";
import { useCoursesUser } from "./users";

export function useCourse(
	courseId: ObjectId | null
): IResourceLoadingInfo<Course> {
	const course = useModelDocById(inject("CourseModel"), courseId);
	const fetchResource = useCallback((courseId: ObjectId | null) => {
		if (!courseId) return;
		const CoursesController = inject("CoursesController");
		return CoursesController.getById({ _id: courseId });
	}, []);
	const resourceInfo = useResourceInfoWithLoading({
		resource: course,
		fetchingArg: courseId!,
		fetch: fetchResource,
		isIdentificationKnown: !!courseId,
	});
	return resourceInfo;
}

const fetchAllCourses = () => {
	return inject("CoursesController").getAll();
};

export const useMyCourses = () => {
	const courses = useModelDocs(inject("CourseModel"));
	const user = useCoursesUser();

	const sortedCourses = useMemo(
		() =>
			!user || !courses
				? null
				: [...courses]
						.filter(course => user.canAccessCourse(course._id))
						.sort((a, b) =>
							b.createdAt instanceof Date &&
							a.createdAt instanceof Date
								? b.createdAt.getTime() - a.createdAt.getTime()
								: 0
						),
		[courses, user]
	);

	const coursesFetchInfo = useResourceInfoWithLoading({
		resource: sortedCourses,
		fetchingArg: undefined,
		fetch: fetchAllCourses,
		isIdentificationKnown: true,
		forcefullyFetch: true,
	});
	return coursesFetchInfo;
};
