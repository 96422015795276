import {
	IAGETUserAnswers,
	IRGETUserAnswers,
	IAGETUserNames,
	IRGETUserNames,
} from "./validators";
import { Requests } from "..";

const users = {
	getAnswers: (data: IAGETUserAnswers): Promise<IRGETUserAnswers> =>
		Requests.send("GET", "/api/users/:folderId/answers", data),
	getUserNames: (data: IAGETUserNames): Promise<IRGETUserNames> =>
		Requests.send("GET", "/api/users/user-names", data),
};
export default users;
